@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Dancing+Script:wght@600&family=Julius+Sans+One&family=Parisienne&family=Poiret+One&display=swap");

html {
   --color-blue-primary: #78c3eb;
   --color-blue-secondary: #97d7f2;
   --color-black-primary: #748187;
   --color-black-primary-o: rgba(116, 129, 135, 0.36);
   --color-blue-light-secondary: #f2f8f9;
   --color-blue-light: #dbf1f7;
   --color-blue-dark: #4a85a1;
   --color-blue-dark-o: rgba(74, 133, 161, 0.03);
   --color-blue-3: #4a85a1;
   --color-yellow: #edce6d;
   --color-purple: #7682c9;
   --color-background: #fcfefc;
   --color-background-o: rgba(252, 254, 252, 0.015);
   --color-card: #78c3eb;
   --radius: 0.3rem;

   --txt: rgba(0 0 0 / 0.51);
}

body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   color: var(--color-black-primary) !important;
}

#root {
   position: absolute;
   top: 0;
   width: 100%;
   overflow-x: hidden;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
}

a {
   /* text-decoration: none !important; */
   color: inherit !important;
}

@media screen and (max-width: 768px) {
}
