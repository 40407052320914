.form-control-color {
   display: block;
   padding: 0;
   width: 2.5em;
   height: 2.5em;
   border-radius: 50%;
   border: none;
   overflow: hidden;
}

.form-control-color:hover {
   display: block;

   width: 2.5em;
   height: 2.5em;
   border-radius: 50%;
   border: 1px var(--color-blue-primary);
}

.form-control {
   border-color: var(--color-blue-primary);
   background-color: var(--color-background);
}

.form-control:focus {
   border-color: var(--color-blue-primary);
   box-shadow: rgba(132, 204, 237, 0.3) 0px 0px 0px 3px;
}

.text-blue {
   color: var(--color-blue-primary);
}

.text-blue-dark {
   color: var(--color-blue-dark);
}

.text-blue-dark {
   color: var(--color-black-secondary);
}

.bg-blue {
   background-color: var(--color-blue-light);
}

.bg-blue-primary {
   background-color: var(--color-blue-primary);
}

th,
td {
   padding: 0.7em;
}

tr:hover {
   background-color: var(--color-blue-secondary);
}

.footer {
   background-color: var(--color-blue-primary);
   color: #fff;
}

.footer a {
   color: var(--color-yellow);
   font-weight: 600;
}

.footer-1 {
   border-top: 1px solid var(--color-blue-primary);
   color: var(--color-blue-primary);
   /* background-color: var(--color-background); */
}

.btn {
   border-radius: 0.6em !important;
}

.btn-primary {
   background-color: var(--color-blue-primary) !important;
   border-color: var(--color-blue-primary) !important;
   color: var(--color-blue-light-secondary) !important;
}

.btn-primary:hover {
   background-color: var(--color-blue-light) !important;
   color: var(--color-blue-primary) !important;
   border-color: var(--color-blue-primary) !important;
}

.btn-yellow {
   background-color: var(--color-yellow);
   color: #fff;
   border-color: var(--color-yellow);
   font-weight: 500;
}

.btn-yellow:hover {
   background-color: #ebc03f;
   color: #fff;
   border-color: var(--color-yellow);
   font-weight: 500;
}

.btn-yellow:active {
   background-color: #ebc03f;
   color: #fff;
   border-color: var(--color-yellow);
   font-weight: 500;
}

.container-cx {
   max-width: 75% !important;
}

.p-blue {
   color: var(--color-blue-primary) !important;
}

.general-background {
   background-color: var(--color-background) !important;
}

.unselectable {
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

.pointer {
   cursor: pointer;
}

.border-t-shirt {
   border-color: var(--color-blue-primary) !important;
}

.navbar-toggler {
   /* border: none !important; */
   height: 3rem;
}

.no-selection {
   -webkit-user-select: none;
   /* Chrome, Safari and Opera */
   -moz-user-select: none;
   /* Firefox */
   -ms-user-select: none;
   /* IE/Edge */
   user-select: none;
   /* Standard syntax */
}

@media screen and (max-width: 820px) {
   .container-cx {
      max-width: 93% !important;
   }
}

@media screen and (max-width: 547px) {
   .container-cx {
      max-width: 100% !important;
      padding: 0;
   }
}

.modal-content {
   border-radius: 0.9em !important;
   border-color: var(--color-blue-primary) !important;
}

.modal-footer,
.modal-header {
   border: none !important;
}

.btn {
   border-radius: var(--radius) !important;
}

.modal {
   padding-left: 0 !important;
}

.menu-selected {
   border-bottom: var(--color-blue-primary) 2px solid !important;
   border-top: var(--color-blue-primary) 2px solid !important;
   border-radius: 0 !important;
   color: var(--color-blue-primary) !important;
   font-weight: 500 ;

}